import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    //templateUrl: 'Hello{{title}}',
    template: '<h1>Hello Ram R KDs{{title}}</h1>',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Save water';
}